// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import TideHeaderLogo from '../../components/BestSchoolsAwards/HeaderLogo/TideHeaderLogo'
import Nav from '../../components/BestSchoolsAwards/Nav/Nav'
import React from 'react'
// import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
// import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const WIBHowItWorks = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <Nav site="tide" theme="dark" />
      <TideHeaderLogo />
      <BSAContent>
        <h1>How it works</h1>
        <p>Win £10,000 for your business in 5 easy steps. </p>
        <ol>
          <li>
            Create a Muddy Stilettos account on the dedicated Muddy Stilettos
            SHE MEANS BUSINESS Award site when it launches at the end of
            September and select if you are an existing or new business.
          </li>
          <li>
            Download and complete the simple entry form. Tell us what makes your
            business special, why you’re passionate about what you do, and the
            impact this huge cash injection will make to your company’s growth.
          </li>
          <li>Submit your entry form.</li>
          <li>
            Create a free{' '}
            <a
              href="https://www.tide.co/offers/muddystilettos-shemeansbusiness/"
              target="_blank"
              rel="noreferrer"
            >
              Tide Business Account
            </a>{' '}
            – it takes under 10 mins to get set up entirely online through their
            website or mobile app
          </li>
          <li>
            Complete your entry by clicking the link in Tide’s account opening
            confirmation email to return to Muddy. Do not miss this step or your
            entry isn’t valid!
          </li>
        </ol>
        <h3>What happens next?</h3>
        <p>
          All successful applications will go before the SHE MEANS BUSINESS
          judging panel consisting of Muddy Stilettos & Tide Bank experts who
          will pick three finalists.
        </p>
        <p>
          The three finalists will be invited to present a three-minute informal
          pitch before the expert judging panel between 1-5pm, Wed 06 December
          2023, at Thames Lido, Reading, Berks.
        </p>
        <p>
          Before attending the Finals:-
          <ul>
            <li>
              If you are an existing business, you will be asked to provide a
              financial statement before you attend the Finals to prove you were
              in net profit for the 2022-23 financial year.{' '}
            </li>
            <li>
              If you are a new business start-ups who has not yet traded for one
              year will need to show a bank statement to prove they are able to
              sustain their business going forward for 12 months in 2024.
            </li>
          </ul>
        </p>
        <p>
          The Finals will be held on 06 December 2023 and the Winner will be
          announced on the Muddy Stilettos site on 12 December 2023.
        </p>
        <p>
          The £10,000 prize money will be transferred to the winner by Muddy
          Stilettos by 30 December 2023.
        </p>
        {/* <p style={{ textAlign: 'center' }}>
          <Link
            to={`/women-in-business/dashboard/`}
            className={`${ContentStyles.RegisterInterestButton} ${ContentStyles.Pink}`}
          >
            Get Started
          </Link>
        </p> */}
      </BSAContent>
      {/* <WhyGetInvolved /> */}
      <BSAFooter />
    </>
  )
}

export default WIBHowItWorks
